import React, { FC } from 'react';
import Modal from 'react-bootstrap/Modal';
import { graphql, Link } from 'gatsby';
import classnames from 'classnames';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Layout from 'layout/Layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import YouTubeLazyLoaded from 'components/YouTubeLazyLoaded';

import { getYoutubeId } from '../../utils/functions';
import { AboutProps } from './models';

import './About.scss';

const About: FC<{ data: AboutProps }> = ({
  data: {
    about: {
      urls,
      seo,
      title,
      image,
      video,
      description,
      chartDescription,
      chartDesktop,
      chartMobile,
      productsTitle,
      productsItems,
      aboutDescription,
    },
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;

  const [show, setShow] = React.useState(false);
  const [videoId, setVideoId] = React.useState(getYoutubeId(video));

  const hideVideo = () => setShow(false);
  const showVideo = (videoUrl) => {
    setVideoId(getYoutubeId(videoUrl));
    setShow(true);
  };

  const CONTAINER = 'container';
  const ABOUT_CONTAINER = 'about';
  const PRODUCT = 'product';
  const PRODUCT_CONTAINER = 'product-container';
  const ABOUT_HEADER = 'about__header';
  const ABOUT_PRODUCT = 'about__product';

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
      />
      <div className={classnames(ABOUT_CONTAINER)}>
        <div className={classnames(`${ABOUT_CONTAINER}--image`)}>
          <button
            type="button"
            onClick={() => showVideo(video)}
            className={classnames(
              `${ABOUT_CONTAINER}__play`,
              'bg-transparent p-0 w-100 border-0 d-block text-center'
            )}
          >
            <Image imageData={image} alt={title} />
          </button>
        </div>
        <div className={classnames(ABOUT_HEADER)}>
          <div className={classnames(CONTAINER, `section-${CONTAINER}`, 'px-0')}>
            <div className="row pb-4">
              <div className="col">
                <div className={classnames(ABOUT_HEADER)}>
                  <div className={classnames(`${ABOUT_HEADER}--summary`)}>
                    {title ? (
                      <DangerouslySetInnerHtml html={title} className="text-uppercase pt-1" />
                    ) : null}
                    {description ? (
                      <DangerouslySetInnerHtml
                        html={description}
                        className={classnames(`${ABOUT_HEADER}--description`)}
                      />
                    ) : null}
                  </div>
                  {chartDescription ? (
                    <DangerouslySetInnerHtml
                      html={chartDescription}
                      className="about__header--subtitle text-center"
                      element="div"
                    />
                  ) : null}

                  <div
                    className={classnames(
                      `${ABOUT_HEADER}--image`,
                      'd-none d-md-block text-center'
                    )}
                  >
                    <Image imageData={chartDesktop} alt={title} className="image" />
                  </div>
                  <div
                    className={classnames(
                      `${ABOUT_HEADER}--image`,
                      'd-block d-md-none text-center'
                    )}
                  >
                    <Image imageData={chartMobile} alt={title} className="mx-auto image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classnames(CONTAINER, PRODUCT_CONTAINER, 'mt-5 pb-5 px-lg-0')}>
          <div className={classnames(`${PRODUCT_CONTAINER}__title`, 'col-12 px-0')}>
            {productsTitle ? <h3 className="text-center">{productsTitle}</h3> : null}
          </div>
          <div className="row pb-5 align-items-center">
            {productsItems.map(
              ({
                title: productTitle,
                subTitle,
                description: descriptionProduct,
                image: productImage,
                link,
                cssExtraClass,
              }) => (
                <div
                  className={classnames(
                    `${ABOUT_PRODUCT}-item`,
                    `${ABOUT_PRODUCT}-item`,
                    'col-12 col-md-6 col-lg-4 px-0'
                  )}
                  key={productTitle}
                >
                  <div className="p-4 p-md-2 p-lg-3 text-center product">
                    <div className={classnames(`${PRODUCT}__img`)}>
                      <Image imageData={productImage} alt={productTitle} className="image" />
                    </div>
                    <h4 className={classnames(`${PRODUCT}__brand`, 'pt-4')}>{productTitle}</h4>
                    <h4 className={classnames(`${PRODUCT}__name`, cssExtraClass)}>{subTitle}</h4>
                    <div
                      className={classnames(
                        `${ABOUT_CONTAINER}-description`,
                        `${PRODUCT}__description`,
                        cssExtraClass
                      )}
                    >
                      <DangerouslySetInnerHtml
                        html={descriptionProduct}
                        element="div"
                        className="text-start text mb-4"
                      />
                      {link ? (
                        <Link type="button" to={link.url} className="link">
                          {link.name}
                        </Link>
                      ) : null}
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
          <DangerouslySetInnerHtml html={aboutDescription} />
        </div>
        <Modal show={show} onHide={hideVideo} size="lg">
          <Modal.Header closeButton />
          <Modal.Body className="w-100 p-0">
            <YouTubeLazyLoaded videoId={videoId} autoplay />
          </Modal.Body>
        </Modal>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    about(url: { eq: $url }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      title
      image {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
          }
        }
      }
      video
      description
      chartDescription
      chartDesktop {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1675, quality: 100)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1675, quality: 100)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1675, quality: 100)
          }
        }
      }
      chartMobile {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 400, quality: 100)
          }
        }
      }
      aboutDescription
      productsTitle
      productsItems {
        title
        subTitle
        cssExtraClass
        description
        link {
          name
          udi
          url
          icon
          queryString
        }
        image {
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 290, quality: 100)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 290, quality: 100)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 290, quality: 100)
            }
          }
        }
      }
    }
  }
`;

export default About;
